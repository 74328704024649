import React, { useState, useEffect } from "react";
import axios from "axios";

function App() {
  const [file, setFile] = useState(null); // To store the uploaded file
  const [prediction, setPrediction] = useState(null); // To store the prediction result
  const [imageUrl, setImageUrl] = useState(null); // To display the uploaded image
  const [error, setError] = useState(null); // To store any error messages

  // Update the document title
  useEffect(() => {
    document.title = "X-ray Pneumonia Detection"; // Set the tab title
  }, []);

  // Handle file selection
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setImageUrl(URL.createObjectURL(e.target.files[0])); // Create a preview of the image
    setError(null); // Clear any previous error
    setPrediction(null); // Reset prediction
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      alert("Please select a file first!");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      // Use the cloud backend URL
      const response = await axios.post("https://wise-coyote-380403.ue.r.appspot.com/predict", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log("Response:", response.data); // Log the response for debugging
      setPrediction(response.data.prediction); // Set the prediction result
      setError(null); // Clear any errors
    } catch (error) {
      console.error("Error during prediction:", error.response || error.message);
      setError(error.response?.data?.error || "An unexpected error occurred.");
      setPrediction(null); // Reset prediction in case of error
    }
  };

  return (
    <div style={{ textAlign: "center", margin: "50px", fontFamily: "Arial, sans-serif" }}>
      {/* Header Section */}
      <header style={{ marginBottom: "40px", padding: "20px", backgroundColor: "#f8f9fa", borderRadius: "8px" }}>
        <h1 style={{ color: "#007bff", fontSize: "36px", marginBottom: "10px" }}>X-ray Pneumonia Detection</h1>
        <p style={{ color: "#333", fontSize: "16px", maxWidth: "600px", margin: "0 auto", marginBottom: "10px" }}>
          This tool uses a machine learning model with ~90% accuracy to assist in detecting pneumonia from chest X-rays.
          Please note that this tool is not a bulletproof diagnostic system and should only be used as an aid alongside
          professional medical consultation.
        </p>
        <h3 style={{ color: "#555", marginBottom: "20px" }}>Please upload a chest X-ray image below.</h3>
      </header>

      {/* Disclaimer Section */}
      <section style={{ marginBottom: "40px", padding: "20px", backgroundColor: "#fff3cd", border: "1px solid #ffeeba", borderRadius: "8px", maxWidth: "600px", margin: "0 auto" }}>
        <h4 style={{ color: "#856404", marginBottom: "10px" }}>Disclaimer:</h4>
        <p style={{ color: "#856404", fontSize: "14px", margin: "0" }}>
          This tool is for informational purposes only and is not intended to replace professional medical advice,
          diagnosis, or treatment. Always seek the advice of your physician or other qualified health provider with any
          questions you may have regarding a medical condition.
        </p>
      </section>

      {/* Form Section */}
      <form onSubmit={handleSubmit} style={{ marginBottom: "30px" }}>
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          style={{
            marginBottom: "20px",
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid #ccc",
            width: "250px",
          }}
        />
        <br />
        <button
          type="submit"
          style={{
            padding: "10px 20px",
            fontSize: "16px",
            borderRadius: "5px",
            border: "none",
            backgroundColor: "#007bff",
            color: "white",
            cursor: "pointer",
          }}
        >
          Predict
        </button>
      </form>

      {/* Uploaded Image Section */}
      {imageUrl && (
        <div style={{ marginTop: "20px" }}>
          <h3 style={{ color: "#555" }}>Uploaded Image:</h3>
          <img
            src={imageUrl}
            alt="Uploaded Preview"
            width="300"
            style={{ border: "1px solid #ddd", borderRadius: "10px", padding: "5px" }}
          />
        </div>
      )}

      {/* Prediction Result Section */}
      {prediction && (
        <div style={{ marginTop: "30px", color: "#333" }}>
          <h2>Prediction:</h2>
          <p style={{ fontSize: "20px", fontWeight: "bold" }}>{prediction}</p>
        </div>
      )}

      {/* Error Message Section */}
      {error && (
        <div style={{ marginTop: "30px", color: "red" }}>
          <h2>Error:</h2>
          <p style={{ fontSize: "18px", fontWeight: "bold" }}>{error}</p>
        </div>
      )}

      {/* Footer Section */}
      <footer style={{ marginTop: "50px", borderTop: "1px solid #ddd", paddingTop: "20px" }}>
        <p style={{ color: "#777", fontSize: "14px" }}>
          Developed by <strong>Ahmed Ali</strong>. Connect with me on{" "}
          <a
            href="https://www.linkedin.com/in/ahmed-s-ali/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#007bff", textDecoration: "none" }}
          >
            LinkedIn
          </a>.
        </p>
      </footer>
    </div>
  );
}

export default App;
